import { gql } from '@apollo/client';

export const CURRENT_USER = gql`
	query currentUser {
		currentUser {
			_id
			name
			role
			email
			phone
			address {
				street_address
				postal_code
				locality
				country
			}
			citizenship
			updatedAt
			userVerifications {
				type
				token
				value
				isVerified
				lastVerified
			}
			userLastVerification
			isPoliticallyExposedPerson
			userPoliticallyExposedPersonRoles {
				type
				name
				address
			}
		}
	}
`;

export const FIND_ONE_USER = gql`
	query findOneUser($_id: ID!) {
		user: findOneUser(_id: $_id) {
			_id
			name
			role
			position
			email
			phone
			address {
				street_address
				postal_code
				locality
				country
			}
			socialno
			dNumber
		}
	}
`;

export const FIND_USER_WITH_USER_ROLES = gql`
	query findOneUser($_id: ID!) {
		user: findOneUser(_id: $_id) {
			_id
			name
			role
			position
			hasLoggedIn
			auditingCompanyId
			email
			phone
			address {
				street_address
				postal_code
				locality
				country
			}
			citizenship
			socialno
			dNumber
			userVerifications {
				type
				lastSent
			}
			userRoles {
				key
				id
				companyName
				role
				roleLabel
			}
			ownerTenants {
				_id
				name
				owners {
					user {
						_id
					}
					shareInPercent
				}
			}
			accountantTenants {
				_id
				name
			}
			accountManagerTenants {
				_id
				name
			}
			auditorTenants {
				_id
				name
			}
			controllerTenants {
				_id
				name
			}
			folderAccess {
				tenantId
				folders
			}
			isPoliticallyExposedPerson
			userPoliticallyExposedPersonRoles {
				type
				name
				address
			}
			identificationFile
			createdAt
			createdByDisplayName
			updatedAt
			updatedByDisplayName
		}
	}
`;

export const PAGINATE_USERS = gql`
	query paginateUsers(
		$order: Int
		$orderBy: String
		$limit: Int
		$offset: Int
		$role: String
		$search: String
	) {
		users: paginateUsers(
			order: $order
			orderBy: $orderBy
			limit: $limit
			skip: $offset
			role: $role
			search: $search
		) {
			count
			items {
				_id
				name
				email
				phone
				address {
					street_address
					postal_code
					locality
					country
				}
				role
				identificationFile
			}
		}
	}
`;

export const PAGINATE_AUDITORS = gql`
	query paginateAuditors(
		$order: Int
		$orderBy: String
		$limit: Int
		$offset: Int
		$search: String
		$filterById: ID
	) {
		auditors: paginateUsers(
			order: $order
			orderBy: $orderBy
			limit: $limit
			skip: $offset
			search: $search
			role: "auditor"
			filterById: $filterById
		) {
			count
			items {
				_id
				name
				auditingCompany {
					name
				}
				auditorTenants {
					_id
				}
			}
		}
	}
`;

export const PAGINATE_TENANT_ADMINS_AND_ACCOUNTANTS = gql`
	query paginateTenantAdminsAndAccountants(
		$order: Int
		$orderBy: String
		$limit: Int
		$offset: Int
		$search: String
	) {
		adminsAndAccountants: paginateUsers(
			order: $order
			orderBy: $orderBy
			limit: $limit
			skip: $offset
			search: $search
			roles: [
				"admin"
				"accountant"
				"authorizedAccountant"
				"authorizedAuditor"
			]
		) {
			count
			items {
				_id
				name
			}
		}
	}
`;

export const FIND_TENANT_USERS = gql`
	query findTenantUsers($tenantId: ID!) {
		users: findTenantUsers(tenantId: $tenantId) {
			count
			items {
				_id
				name
				role
				email
				phone
			}
		}
	}
`;

export const FIND_TENANT_BOARD_MEMBER_USERS = gql`
	query findTenantBoardMemberUsers($tenantId: ID!) {
		users: findTenantBoardMemberUsers(tenantId: $tenantId) {
			count
			items {
				_id
				name
				role
				email
				phone
			}
		}
	}
`;

export const PAGINATE_ACCOUNTANTS = gql`
	query paginateAccountants(
		$order: Int
		$orderBy: String
		$limit: Int
		$offset: Int
		$search: String
	) {
		accountants: paginateAccountants(
			order: $order
			orderBy: $orderBy
			limit: $limit
			skip: $offset
			search: $search
		) {
			count
			items {
				_id
				name
				position
				roleName
				accountantRoles {
					accountantTenants {
						_id
					}
					accountManagerTenants {
						_id
					}
					controllerTenants {
						_id
					}
				}
			}
		}
	}
`;

export const GET_AUTHORIZED_DATA = gql`
	query getAuthorizedData($_id: ID!) {
		tenant: findOneTenant(_id: $_id) {
			_id
			name
			accountants {
				_id
				name
			}
			accountManagers {
				_id
				name
			}
			auditors {
				_id
				name
			}
			controllers {
				_id
				name
			}
		}
		user: currentUser {
			_id
			name
			role
			email
			phone
			scopes
			userVerifications {
				type
				token
				value
				isVerified
				lastVerified
			}
			isPoliticallyExposedPerson
			userPoliticallyExposedPersonRoles {
				type
				name
				address
			}
		}
	}
`;

/**
 * User profile queries for roles: user and auditor
 */

export const FIND_USER_PROFILE = gql`
	query findUserProfile($_id: ID!) {
		user: findUserProfile(_id: $_id) {
			_id
			name
			email
			phone
		}
	}
`;
