import { useState } from 'react';
import { useQuery } from '@apollo/client';

import theme from 'components/theme';

import { useAppFilters } from 'web/contexts/AppFiltersContext';

import CompanyContractsDialog from 'web/components/widgets/dialogs/CompanyContractsDialog';

import {
	WidgetList,
	WidgetListItem,
	WidgetListLabel,
} from 'web/components/widgets/components/WidgetList';
import WidgetListData from 'web/components/widgets/components/WidgetListData';

import ErrorAlert from 'components/ErrorAlert';

import Widget from 'web/components/widgets/Widget';

import {
	COMPANIES_WITHOUT_CONTRACTS,
	COMPANIES_WITH_INCOMPLETE_CONTRACTS,
	COMPANY_CONTRACTS_MISSING_SIGNATURES,
} from 'api/queries/widgetQueries';

export default function CompanyContractsWidget() {
	const { department, accountant, accountantRole, company } = useAppFilters();

	const [showCompanyContractsDialog, setShowCompanyContractsDialog] =
		useState(false);

	const {
		data: { items } = {
			items: [],
		},
		error,
		loading,
	} = useQuery(COMPANY_CONTRACTS_MISSING_SIGNATURES, {
		variables: {
			filter: {
				departmentId: department?.value,
				accountantId: accountant?.value,
				accountantRole: accountantRole?.value,
				company: company?.value,
			},
		},
		fetchPolicy: 'no-cache',
	});

	return (
		<Widget title="Oppdragsavtaler" titleIcon="file-signature">
			<ErrorAlert error={error} />

			<WidgetList>
				<WidgetListData
					label={
						<WidgetListLabel
							label="Mangler oppdragsavtale"
							chipColor={theme.palette.status.error}
						/>
					}
					query={COMPANIES_WITHOUT_CONTRACTS}
					link={id => `/bedrifter/${id}/rediger/?tab=oppdrag`}
				/>

				<WidgetListData
					label={
						<WidgetListLabel
							label="Ufulstendige oppdragsavtaler"
							chipColor={theme.palette.status.warning}
						/>
					}
					query={COMPANIES_WITH_INCOMPLETE_CONTRACTS}
					link={id => `/bedrifter/${id}/rediger/?tab=oppdrag`}
					hideIfEmpty={true}
				/>

				<WidgetListItem
					label={
						<WidgetListLabel
							label="Venter på signering"
							chipColor={theme.palette.status.warning}
						/>
					}
					value={items?.length ?? 0}
					loading={loading}
					onClick={() => setShowCompanyContractsDialog(true)}
				/>
			</WidgetList>

			<CompanyContractsDialog
				contracts={items}
				open={showCompanyContractsDialog}
				onClose={() => setShowCompanyContractsDialog(false)}
			/>
		</Widget>
	);
}
