import { useState } from 'react';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import styled from 'styled-components';
import Button from '@mui/material/Button';
import DescriptionIcon from '@mui/icons-material/Description';

import { useUser } from 'web/contexts/UserContext';

import Table, { TableRow, TableCell } from 'web/components/Table';
import SearchBar from 'web/components/SearchBar';
import DocumentSearch from 'web/components/views/DocumentSearch';
import FolderCompanyRoutines from 'web/components/FolderCompanyRoutines';
import FolderCompanyContracts from 'web/components/FolderCompanyContracts';
import FileDropArea from 'web/components/FileDropArea';
import DocumentFolderRow from 'web/components/DocumentFolderRow';
import DocumentFileRow from 'web/components/DocumentFileRow';
import SortIcon from 'web/components/SortIcon';
import CreateMenu from 'web/components/CreateMenu';

const Header = styled.div`
	margin-bottom: 20px;
	display: grid;
	grid-template-columns: 1fr;
	gap: 20px;
	margin-bottom: 20px;
`;

const NothingFoundWrapper = styled.div`
	text-align: center;
	padding: 40px 0;
	opacity: 0.2;
	width: 100%;
`;

const ActiveStatusButtonWrapper = styled.div`
	position: absolute;
	right: 30px;
	margin-top: 10px;
`;

export default function FileBrowser({
	documents,
	parentId = 'root',
	parentName,
	tenantId,
	linkBase,
	sort,
	setNewOrder,
	includeHidden = false,
	setIncludeHidden = null,
}) {
	const { user, userHasMinRole } = useUser();
	const [search, setSearch] = useState({
		isSearching: false,
		searchValue: '',
	});
	const folders =
		documents && documents.items.filter(d => d.type === 'folder');
	const files = documents && documents.items.filter(d => d.type === 'file');

	const handleSearch = searchValue => {
		if (searchValue.length > 2) {
			setSearch({ isSearching: true, searchValue });
		} else {
			setSearch({ isSearching: false, searchValue: '' });
		}
	};

	return (
		<DndProvider backend={HTML5Backend}>
			<Header>
				<SearchBar
					label={'Søk i dokumenter...'}
					updateSearch={handleSearch}
				/>

				<FolderCompanyRoutines
					folderId={parentId}
					companyId={tenantId}
				/>
			</Header>

			{search.isSearching && (
				<DocumentSearch
					search={search.searchValue}
					tenantId={tenantId}
				/>
			)}

			{!search.isSearching && folders && !!folders.length && (
				<>
					<Table columns="5" style={{ marginBottom: '30px' }}>
						<TableRow header>
							<TableCell
								start="1"
								end="3"
								onClick={() => setNewOrder('name')}
							>
								<span>Mappenavn</span>

								<SortIcon
									field="name"
									orderBy={sort.orderBy}
									order={sort.order}
								/>
							</TableCell>

							<TableCell center>Mangler signering</TableCell>

							<TableCell center>Ferdig signert</TableCell>

							<TableCell center>Filer</TableCell>
						</TableRow>

						{folders.map((folder, i) => (
							<DocumentFolderRow
								key={i}
								_id={folder._id}
								name={folder.name}
								linkBase={linkBase}
								tenantId={tenantId}
								signedDocuments={folder.signedDocuments}
								unsignedDocuments={folder.unsignedDocuments}
								filesCount={folder.filesCount || 0}
								seenByCurrentUser={folder.seenByCurrentUser}
								showMenu={!folder.locked}
								canDelete={
									folder.createdBy === user._id ||
									userHasMinRole('accountant')
								}
								isHidden={folder.isHidden}
							/>
						))}
					</Table>
				</>
			)}

			{!search.isSearching && parentId !== 'root' && files && (
				<>
					<FolderCompanyContracts
						companyId={tenantId}
						parentName={parentName}
					/>

					<FileDropArea parentId={parentId} tenantId={tenantId}>
						<Table columns="5">
							<TableRow header>
								<TableCell
									start="1"
									end="4"
									onClick={() => setNewOrder('name')}
								>
									<span>Filnavn</span>

									<SortIcon
										field="name"
										orderBy={sort.orderBy}
										order={sort.order}
									/>
								</TableCell>

								<TableCell center>Avventer signering</TableCell>

								<TableCell center>Utført signering</TableCell>
							</TableRow>

							{files.length === 0 && (
								<TableRow>
									<TableCell
										start="1"
										end="-1"
										onClick={() => setNewOrder('name')}
									>
										<NothingFoundWrapper>
											<DescriptionIcon
												sx={{ fontSize: 55 }}
											/>

											<div>
												{includeHidden
													? 'Mappen inneholder ingen arkiverte filer'
													: 'Mappen inneholder ingen filer'}
											</div>
										</NothingFoundWrapper>
									</TableCell>
								</TableRow>
							)}

							{files.map((file, i) => (
								<DocumentFileRow
									key={i}
									_id={file._id}
									name={file.name}
									to={`${linkBase}${file._id}/`}
									tenantId={tenantId}
									isSigning={file.isSigning}
									isSigningComplete={file.isSigningComplete}
									signeesSigned={file.signeesSigned}
									signeesWaiting={file.signeesWaiting}
									seenByCurrentUser={file.seenByCurrentUser}
									showMenu={!file.locked}
									canDelete={
										file.createdBy === user._id ||
										userHasMinRole('accountant')
									}
									isHidden={file.isHidden}
								/>
							))}
						</Table>

						{setIncludeHidden && (
							<ActiveStatusButtonWrapper>
								<Button
									variant="contained"
									color="primary"
									onClick={() =>
										setIncludeHidden(!includeHidden)
									}
								>
									{includeHidden
										? 'Skjul arkiverte'
										: 'Vis arkiverte'}
								</Button>
							</ActiveStatusButtonWrapper>
						)}
					</FileDropArea>
				</>
			)}

			{parentId !== 'root' && parentId && (
				<CreateMenu
					tenantId={tenantId}
					parentId={parentId}
					order={sort.order}
					orderBy={sort.orderBy}
					includeHidden={includeHidden}
				/>
			)}
		</DndProvider>
	);
}
