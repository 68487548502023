import { Fragment } from 'react';
import styled from 'styled-components';
import { useNavigate, Link } from 'react-router-dom';
import { useQuery, useMutation } from '@apollo/client';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Divider from '@mui/material/Divider';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';

import { useSnackbar } from 'web/contexts/SnackbarContext';
import { useConfirmDialog } from 'components/contexts/ConfirmContext';

import Panel from 'web/components/panels/Panel';
import { StyledListWithA } from 'components/MaterialList';
import { EmptyList } from 'components/MaterialList';
import { RiskScorePill } from 'components/Pill';

import { RevisionText } from 'components/Revision';
import ErrorAlert from 'components/ErrorAlert';

import { GET_COMPANY_RISKS } from 'api/queries/companyRiskQueries';
import {
	INSERT_ONE_COMPANY_RISK,
	DELETE_ONE_COMPANY_RISK,
} from 'api/mutations/companyRiskMutations';

const AddButtonWrapper = styled.div`
	text-align: right;
`;

export default function CompanyRisksPanel({ companyId }) {
	const navigate = useNavigate();

	const { notify } = useSnackbar();
	const { verify } = useConfirmDialog();

	const {
		data,
		loading: getCompanyRisksLoading,
		error: getCompanyRisksError,
	} = useQuery(GET_COMPANY_RISKS, {
		variables: {
			companyId,
		},
	});

	const [createCompanyRisk, { error: createCompanyRiskError }] = useMutation(
		INSERT_ONE_COMPANY_RISK
	);

	const [deleteCompanyRisk, { error: deleteCompanyRiskError }] = useMutation(
		DELETE_ONE_COMPANY_RISK,
		{
			refetchQueries: ['findCompanyRisks'],
		}
	);

	if (getCompanyRisksLoading) return null;

	return (
		<Panel
			title="Hvitvasking"
			action={
				<AddButtonWrapper>
					<Button
						size="medium"
						variant="contained"
						color="primary"
						disabled={data?.companyRisks?.length > 0}
						endIcon={<AddIcon size="small" />}
						onClick={async () => {
							try {
								const { data } = await createCompanyRisk({
									variables: { companyId },
								});

								if (data) {
									navigate(
										`/bedrifter/${companyId}/hvitvasking/${data.risk._id}/rediger/`
									);
								}
							} catch (err) {
								console.error(err);
							}
						}}
					>
						Opprett
					</Button>
				</AddButtonWrapper>
			}
		>
			<ErrorAlert error={getCompanyRisksError} />

			<ErrorAlert error={createCompanyRiskError} />

			<ErrorAlert error={deleteCompanyRiskError} />

			{data?.companyRisks?.length > 0 ? (
				<StyledListWithA>
					{data.companyRisks.map(
						({
							_id,
							name,
							revision,
							needsRevision,
							totalScore,
						}) => (
							<Fragment key={_id}>
								<ListItem
									secondaryAction={
										<div>
											<RiskScorePill $score={totalScore}>
												{totalScore ?? 'Ikke vurdert'}
											</RiskScorePill>
											<IconButton
												edge="end"
												aria-label="Slett"
												role="button"
												onClick={async () => {
													try {
														if (
															!(await verify({
																title: 'Slett risikovudering',
																text: `Er du sikker på at du vil slette ${name}?`,
															}))
														) {
															return;
														}

														const { data } =
															await deleteCompanyRisk(
																{
																	variables: {
																		_id: _id,
																	},
																}
															);

														if (data?.deleted) {
															notify(
																'Risikovudering ble slettet!'
															);
														}
													} catch (err) {
														console.error(err);
													}
												}}
											>
												<DeleteIcon />
											</IconButton>
										</div>
									}
								>
									<ListItemText>
										<Link
											to={`/bedrifter/${companyId}/hvitvasking/${_id}/rediger/`}
										>
											{name}
										</Link>

										<RevisionText
											needsRevision={needsRevision}
											date={revision?.date}
											by={revision?.byDisplayName}
										/>
									</ListItemText>
								</ListItem>

								<Divider component="li" />
							</Fragment>
						)
					)}
				</StyledListWithA>
			) : (
				<>
					<EmptyList>
						Bedriften har ingen skjema for hvitvasking.
					</EmptyList>
				</>
			)}
		</Panel>
	);
}
