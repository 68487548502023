import { useState } from 'react';
import styled from 'styled-components';
import { useQuery, useMutation } from '@apollo/client';
import { useNavigate, useParams } from 'react-router-dom';
import { createPortal } from 'react-dom';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import ChatIcon from '@mui/icons-material/Chat';

import { pluralize } from 'utils/strings';
import { formatDate } from 'utils/dates';

import { useSnackbar } from 'web/contexts/SnackbarContext';
import useScrollTo from 'hooks/useScrollTo';

import CompanyRiskCreateActionModal from 'web/screens/modals/CompanyRiskCreateActionModal';
import CompanyRiskUpdateQuestionModal from 'web/screens/modals/CompanyRiskUpdateQuestionModal';
import CompanyRiskAddCommentModal from 'web/screens/modals/CompanyRiskAddCommentModal';

import Message from 'components/messages/Message';
import ErrorMessage from 'components/ErrorMessage';
import Revision from 'components/Revision';
import DeleteButton from 'components/buttons/DeleteButton';

import DesktopLayout, {
	MediumContent,
	StickyAction,
} from 'web/components/Layout';
import HelpText from 'web/components/HelpText';

import Form, { FormGroup, MarkdownField, Select } from 'web/form/Form';

import {
	GET_ONE_COMPANY_RISK,
	GET_CATEGORY_SCORE,
} from 'api/queries/companyRiskQueries';
import {
	UPDATE_ONE_COMPANY_RISK_QUESTION,
	REVISE_ONE_COMPANY_RISK,
	DELETE_ONE_COMPANY_RISK,
} from 'api/mutations/companyRiskMutations';

import { GET_COMPANY_RISK_ACTIONS } from 'api/queries/companyRiskActionsQueries';
import {
	MARK_COMPANY_RISK_ACTION_AS_DONE,
	DELETE_ONE_COMPANY_RISK_ACTION,
} from 'api/mutations/companyRiskActionMutations';

const Title = styled.h1`
	margin: 0 0 30px 0;
`;

const Category = styled.div`
	margin-bottom: 30px;
`;

const Section = styled.div`
	background-color: ${p => p.theme.palette.background.default};
	border: 1px solid ${p => p.theme.palette.background.paper};
	margin-bottom: 10px;
	padding: 20px 40px;
`;

const SectionContent = styled.div``;

const SectionTitle = styled.h2`
	margin: 0 0 20px 0;
`;

export default function CompanyRiskEdit() {
	const navigate = useNavigate();
	const { riskId } = useParams();
	const { notify } = useSnackbar();
	const [scrollToRef, scrollToElement] = useScrollTo();

	const {
		data: { companyRisk } = { companyRisk: null },
		error: getCompanyRiskError,
	} = useQuery(GET_ONE_COMPANY_RISK, {
		variables: {
			_id: riskId,
		},
	});

	const [reviseRisk, { loading: reviseRiskLoading, error: reviseRiskError }] =
		useMutation(REVISE_ONE_COMPANY_RISK, {
			refetchQueries: ['findOneCompanyRisk'],
		});

	const [deleteRisk, { loading: deleteRiskLoading, error: deleteRiskError }] =
		useMutation(DELETE_ONE_COMPANY_RISK, {
			refetchQueries: ['findOneCompanyRisk'],
		});

	return (
		<DesktopLayout
			title="Rediger risiko"
			breadcrumbs={[
				{
					to: '/bedrifter/',
					label: 'Bedrifter',
				},
				{
					to: `/bedrifter/${companyRisk?.company?._id}/rediger/`,
					label: companyRisk?.company?.name || '…',
				},
				{
					to: `/bedrifter/${companyRisk?.company?._id}/rediger/?tab=hvitvasking`,
					label: 'Hvitvasking',
				},
				{
					label: companyRisk?.name || '...',
				},
			]}
		>
			<MediumContent ref={scrollToRef}>
				<Title>{companyRisk?.name}</Title>

				<ErrorMessage
					errors={[
						getCompanyRiskError,
						reviseRiskError,
						deleteRiskError,
					]}
				/>

				{companyRisk?.hasChanges && (
					<Message type="warning" title="NB! Skjemaet har endringer">
						Skjemaet inneholder endringer som ikke er revidert!
					</Message>
				)}

				<Revision
					needsRevision={companyRisk?.needsRevision}
					date={companyRisk?.revision?.date}
					by={companyRisk?.revision?.byDisplayName}
				/>

				{companyRisk?.categories?.length > 0 &&
					companyRisk.categories.map(category => (
						<Category key={category._id}>
							<SectionTitle>{category.name}</SectionTitle>

							<div>
								{category.questions.map(question => (
									<Question
										key={question._id}
										question={question}
										company={companyRisk.company}
									/>
								))}
							</div>

							<CategoryScore categoryId={category._id} />
						</Category>
					))}

				{companyRisk && (
					<Form
						values={{
							totalEvaluation: companyRisk?.totalEvaluation || '',
							totalScore:
								companyRisk?.totalScore ??
								companyRisk?.calculatedScore,
						}}
						onSubmit={async values => {
							try {
								const { data } = await reviseRisk({
									variables: {
										_id: riskId,
										...values,
									},
								});

								if (data?.revised) {
									notify('Risikovurderingen er revidert');

									navigate();
								}
							} catch (err) {
								console.error(err);

								scrollToElement();
							}
						}}
					>
						<TotalAssessment
							calculatedScore={companyRisk?.calculatedScore}
						/>

						<StickyAction>
							<DeleteButton
								disabled={deleteRiskLoading}
								onClick={async () => {
									const { data } = await deleteRisk({
										variables: {
											_id: companyRisk?._id,
										},
									});

									if (!data.deleted) {
										throw new Error(
											'Det oppstod en feil ved sletting!'
										);
									}
								}}
								redirect={`/bedrifter/${companyRisk?.company?._id}/rediger/?tab=hvitvasking`}
							/>

							<Button
								type="submit"
								variant="contained"
								color="primary"
								size="large"
								label="Revider risiko"
								disabled={reviseRiskLoading}
							>
								Revider
							</Button>
						</StickyAction>
					</Form>
				)}
			</MediumContent>
		</DesktopLayout>
	);
}

function CategoryScore({ categoryId }) {
	const { data: { categoryScore } = { categoryScore: null } } = useQuery(
		GET_CATEGORY_SCORE,
		{
			variables: {
				categoryId,
			},
		}
	);

	return <Score label="Vurdering" score={categoryScore} />;
}

const ScoreWrapper = styled.div`
	background-color: ${p => p.theme.palette.background.default};
	border: 1px solid ${p => p.theme.palette.background.paper};
	padding: 20px 40px;
	margin: 0 0 40px 0;
`;

const ScoreContent = styled.div`
	text-align: right;
`;

const ScoreLabel = styled.span`
	margin-right: 10px;
	font-weight: bold;
`;

const ScoreValue = styled.span``;

function Score({ label, score }) {
	return (
		<ScoreWrapper>
			<ScoreContent>
				<ScoreLabel>{label}:</ScoreLabel>

				<ScoreValue>{score} risiko</ScoreValue>
			</ScoreContent>
		</ScoreWrapper>
	);
}

const TotalAssesmentText = styled.p`
	margin: 0 0 30px 0;
`;

function TotalAssessment({ calculatedScore }) {
	return (
		<Section>
			<SectionTitle>Totalvurdering</SectionTitle>

			<SectionContent>
				<TotalAssesmentText>
					Basert på risikovurderingen er bedriften vurdert til å ha{' '}
					<strong>{calculatedScore.toLowerCase()} risiko</strong> for
					hvitvasking.
					<br />
					Denne vurderingen kan ved behov overstyres nedenfor.
				</TotalAssesmentText>

				<FormGroup>
					<Select
						name="totalScore"
						label="Totalvurdering"
						options={[
							{
								value: 'Lav',
								label: 'Lav',
							},
							{
								value: 'Normal',
								label: 'Normal',
							},
							{
								value: 'Høy',
								label: 'Høy',
							},
						]}
						fullWidth
						required
					/>
				</FormGroup>

				<FormGroup>
					<MarkdownField
						name="totalEvaluation"
						aria-label="Kommentar til evalueringen"
					/>
				</FormGroup>
			</SectionContent>
		</Section>
	);
}

const QuestionWrapper = styled.div`
	background-color: ${p => p.theme.palette.background.default};
	border: 1px solid ${p => p.theme.palette.background.paper};
	margin-bottom: 10px;
	padding: 20px 40px;
`;

const QuestionContent = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
	gap: 20px;
	position: relative;
`;

const QuestionLabel = styled.div`
	font-weight: bold;
	margin: 0;
`;

const QuestionDescription = styled.div`
	font-style: italic;
	margin-top: 20px;
`;

const ChoicesButtonGroup = styled.div`
	display: flex;
	gap: 10px;
`;

function Question({ question, company }) {
	switch (question.type) {
		case 'business-codes':
			return (
				<BusinessCodesQuestion question={question} company={company} />
			);
		case 'company-non-authenticated-users':
			return (
				<CompanyNonAuthenticatedUsersQuestion
					question={question}
					company={company}
				/>
			);
		case 'company-non-authenticated-owners':
			return (
				<CompanyNonAuthenticatedOwnersQuestion
					question={question}
					company={company}
				/>
			);
		case 'company-pep-users':
			return (
				<CompanyPepUsersQuestion
					question={question}
					company={company}
				/>
			);
		case 'general':
			return <GeneralQuestion question={question} />;
		default:
			console.error('Question type not implemented', question.type);
			return <div>Ikke implementert</div>;
	}
}

function BusinessCodesQuestion({ question, company }) {
	return (
		<QuestionWrapper>
			<QuestionContent>
				<QuestionLabel>{question.label}</QuestionLabel>

				<Choices
					questionId={question._id}
					choices={question.choices}
					value={question.value}
					requiredComment={question?.requiredComment ?? true}
					comment={question?.comment}
				/>

				<HelpText text={question.help} absolutePosition />
			</QuestionContent>

			{company.businessCodes ? (
				<QuestionDescription>
					<div>
						{company.name} er reigstrert med følgende{' '}
						{pluralize(
							'næringskode:',
							'næringskoder:',
							company.businessCodes
						)}
					</div>

					<ul>
						{company.businessCodes.map((code, index) => (
							<li key={index}>{code}</li>
						))}
					</ul>
				</QuestionDescription>
			) : (
				<QuestionDescription>
					{company.name} mangler næringskode.
				</QuestionDescription>
			)}

			{question.comment && <Comment comment={question.comment} />}

			<Actions questionId={question._id} />
		</QuestionWrapper>
	);
}

function CompanyNonAuthenticatedUsersQuestion({ question, company }) {
	return (
		<QuestionWrapper>
			<QuestionContent>
				<QuestionLabel>{question.label}</QuestionLabel>

				<Choices
					questionId={question._id}
					choices={question.choices}
					value={question.value}
					requiredComment={question?.requiredComment ?? true}
					comment={question?.comment}
					disabled={true}
				/>

				<HelpText text={question.help} absolutePosition />
			</QuestionContent>

			{company.unAutenhicatedUsers ? (
				<QuestionDescription>
					<div>Styremedlemmer som ikke er verifisert:</div>

					<ul>
						{company.unAutenhicatedUsers.map(user => (
							<li key={user._id}>{user.name}</li>
						))}
					</ul>
				</QuestionDescription>
			) : (
				<QuestionDescription>
					Alle styremedlemmer er verifisert.
				</QuestionDescription>
			)}

			{question.comment && <Comment comment={question.comment} />}

			<Actions questionId={question._id} />
		</QuestionWrapper>
	);
}

function CompanyNonAuthenticatedOwnersQuestion({ question, company }) {
	return (
		<QuestionWrapper>
			<QuestionContent>
				<QuestionLabel>{question.label}</QuestionLabel>

				<Choices
					questionId={question._id}
					choices={question.choices}
					value={question.value}
					requiredComment={question?.requiredComment ?? true}
					comment={question?.comment}
					disabled={true}
				/>

				<HelpText text={question.help} absolutePosition />
			</QuestionContent>

			{company.unAutenhicatedOwners ? (
				<QuestionDescription>
					<div>Reelle rettighetshavere som ikke er verifisert:</div>

					<ul>
						{company.unAutenhicatedOwners.map(user => (
							<li key={user._id}>{user.name}</li>
						))}
					</ul>
				</QuestionDescription>
			) : (
				<QuestionDescription>
					Alle reelle rettighetshavere er verifisert.
				</QuestionDescription>
			)}

			{question.comment && <Comment comment={question.comment} />}

			<Actions questionId={question._id} />
		</QuestionWrapper>
	);
}

function CompanyPepUsersQuestion({ question, company }) {
	return (
		<QuestionWrapper>
			<QuestionContent>
				<QuestionLabel>{question.label}</QuestionLabel>

				<Choices
					questionId={question._id}
					choices={question.choices}
					value={question.value}
					requiredComment={question?.requiredComment ?? true}
					comment={question?.comment}
					disabled={true}
				/>

				<HelpText text={question.help} absolutePosition />
			</QuestionContent>

			{company.pepUsers ? (
				<QuestionDescription>
					<div>
						Styremedlemmer og reelle rettihetshavere som er politisk
						eksponert:
					</div>

					<ul>
						{company.pepUsers.map(user => (
							<li key={user._id}>{user.name}</li>
						))}
					</ul>
				</QuestionDescription>
			) : (
				<QuestionDescription>
					Ingen styremedlemmer eller reelle rettihetshavere er
					politisk eksponert.
				</QuestionDescription>
			)}

			{question.comment && <Comment comment={question.comment} />}

			<Actions questionId={question._id} />
		</QuestionWrapper>
	);
}

function GeneralQuestion({ question }) {
	return (
		<QuestionWrapper>
			<QuestionContent>
				<QuestionLabel>{question.label}</QuestionLabel>

				<Choices
					questionId={question._id}
					choices={question.choices}
					value={question.value}
					requiredComment={question?.requiredComment ?? true}
					comment={question?.comment}
				/>

				<HelpText text={question.help} absolutePosition />
			</QuestionContent>

			{question.description && (
				<QuestionDescription>
					{question.description}
				</QuestionDescription>
			)}

			{question.comment && <Comment comment={question.comment} />}

			<Actions questionId={question._id} />
		</QuestionWrapper>
	);
}

const ChoiceButton = styled.button`
	background-color: ${p =>
		p.$active
			? p.negative
				? p.theme.palette.status.error
				: p.theme.palette.status.success
			: 'transparent'};
	border: 1px solid
		${p =>
			p.$negative
				? p.theme.palette.status.error
				: p.theme.palette.status.success};
	color: ${p =>
		p.$active ? p.theme.palette.text.white : p.theme.palette.text.primary};
	cursor: ${p => (p.$disabled ? 'not-allowed' : 'pointer')};
	font-size: 0.8125rem;
	min-width: 55px;
	padding: 4px 10px;
	transition: all 0.5s;
`;

function Choices({
	questionId,
	choices,
	value,
	requiredComment,
	comment = null,
	disabled = false,
}) {
	const { notify } = useSnackbar();
	const [selectedValue, setSelectedValue] = useState(0);

	const [upateQuestion, { loading, error }] = useMutation(
		UPDATE_ONE_COMPANY_RISK_QUESTION,
		{
			refetchQueries: ['findOneCompanyRisk', 'getCategoryScore'],
		}
	);

	return (
		<>
			<ChoicesButtonGroup>
				<AddCommentButton questionId={questionId} comment={comment} />

				{choices.map(choice => (
					<ChoiceButton
						key={choice.value}
						$disabled={
							disabled || loading || choice.value === value
						}
						$active={choice.value === value}
						$negative={choice.value > 0}
						onClick={async () => {
							if (requiredComment && choice.value > 0) {
								setSelectedValue(choice.value);
							} else {
								try {
									setSelectedValue(choice.value);

									const { data } = await upateQuestion({
										variables: {
											questionId,
											value: choice.value,
											comment: '',
										},
									});

									if (data.updated) {
										setSelectedValue(null);
										notify('Oppdatert');
									}
								} catch (err) {
									console.error(err);
								}
							}
						}}
					>
						{choice.label}
					</ChoiceButton>
				))}
			</ChoicesButtonGroup>

			<ErrorMessage errors={error} />

			{requiredComment &&
				selectedValue > 0 &&
				createPortal(
					<CompanyRiskUpdateQuestionModal
						questionId={questionId}
						value={selectedValue}
						onClose={() => setSelectedValue(null)}
					/>,
					document.getElementById('modal-root')
				)}
		</>
	);
}

function AddCommentButton({ questionId, comment }) {
	const [showAddComentModal, setShowAddComentModal] = useState(false);

	return (
		<div>
			<IconButton
				aria-label="Legg til kommentar"
				title="Legg til kommentar"
				size="small"
				onClick={() => setShowAddComentModal(true)}
			>
				<ChatIcon fontSize="inherit" />
			</IconButton>

			{showAddComentModal &&
				createPortal(
					<CompanyRiskAddCommentModal
						questionId={questionId}
						comment={comment}
						onClose={() => setShowAddComentModal(null)}
					/>,
					document.getElementById('modal-root')
				)}
		</div>
	);
}

const CommentWrapper = styled.div`
	margin-top: 20px;
`;

const CommentTitle = styled.h4`
	margin: 0 0 10px 0;
`;

function Comment({ comment }) {
	return (
		<CommentWrapper>
			<CommentTitle>Kommentar</CommentTitle>
			{comment}
		</CommentWrapper>
	);
}

const ActionsWrapper = styled.div`
	margin-top: 30px;
`;

const ActionsList = styled.div`
	margin-bottom: 20px;
`;

const ActionListHeader = styled.div`
	font-weight: bold;
	display: grid;
	grid-template-columns: 3fr 1fr 1fr 1fr;
	gap: 20px;
	align-items: center;
	padding: 5px 10px;
`;

const Action = styled.div`
	background-color: ${p => p.theme.palette.background.default};
	display: grid;
	grid-template-columns: 3fr 1fr 1fr 1fr;
	gap: 20px;
	align-items: center;
	margin-bottom: 5px;
	padding: 5px 10px;
`;

const ActionDueDate = styled.div`
	color: ${p =>
		p.$isOverdue
			? p.theme.palette.status.error
			: p.theme.palette.status.success};
`;

const ActionRemoveButtonWrapper = styled.div`
	text-align: right;
`;

const ActionAddButtonWrapper = styled.div`
	display: flex;
	justify-content: flex-end;
`;

function Actions({ questionId }) {
	const { notify } = useSnackbar();

	const { data: { actions } = { actions: null }, error: getActionsError } =
		useQuery(GET_COMPANY_RISK_ACTIONS, { variables: { questionId } });

	const [showCreateActionModal, setShowCreateActionModal] = useState(false);

	const [
		markActionAsDone,
		{ error: markDoneError, loading: markDoneLoading },
	] = useMutation(MARK_COMPANY_RISK_ACTION_AS_DONE, {
		refetchQueries: ['findCompanyRiskActions'],
	});

	const [
		deleteAction,
		{ error: deleteActionError, loading: deleteActionLoading },
	] = useMutation(DELETE_ONE_COMPANY_RISK_ACTION, {
		refetchQueries: ['findCompanyRiskActions'],
	});

	const undoneActions = actions?.filter(action => !action.done) || [];
	const doneActions = actions?.filter(action => action.done) || [];

	return (
		<ActionsWrapper>
			<ErrorMessage
				errors={[getActionsError, markDoneError, deleteActionError]}
			/>

			{undoneActions && undoneActions.length > 0 && (
				<ActionsList>
					<ActionListHeader>
						<div>Tiltak</div>

						<div>Frist</div>

						<div></div>
					</ActionListHeader>

					{undoneActions.map(action => (
						<Action key={action._id}>
							<div>{action.description}</div>

							<ActionDueDate $isOverdue={action.isOverdue}>
								{formatDate(action.dueDate, 'DD.MM.YYYY')}
							</ActionDueDate>

							<ActionRemoveButtonWrapper>
								<Button
									disabled={markDoneLoading}
									onClick={async () => {
										try {
											markActionAsDone({
												variables: {
													_id: action._id,
												},
											});
										} catch (err) {
											console.error(err);
										}
									}}
								>
									Fullfør
								</Button>
							</ActionRemoveButtonWrapper>

							<ActionRemoveButtonWrapper>
								<Button
									disabled={deleteActionLoading}
									onClick={async () => {
										try {
											const { data } = await deleteAction(
												{
													variables: {
														_id: action._id,
													},
												}
											);

											if (data.deleted) {
												notify('Slettet');
											}
										} catch (err) {
											console.error(err);
										}
									}}
								>
									Fjern
								</Button>
							</ActionRemoveButtonWrapper>
						</Action>
					))}
				</ActionsList>
			)}

			{doneActions && doneActions.length > 0 && (
				<ActionsList>
					<ActionListHeader>
						<div>Utførte tiltak</div>
					</ActionListHeader>

					{doneActions.map(action => (
						<Action key={action._id}>
							<div>{action.description}</div>
						</Action>
					))}
				</ActionsList>
			)}

			<ActionAddButtonWrapper>
				<Button
					variant="contained"
					size="small"
					color="primary"
					onClick={() => setShowCreateActionModal(true)}
				>
					Legg til tiltak
				</Button>
			</ActionAddButtonWrapper>

			{showCreateActionModal &&
				createPortal(
					<CompanyRiskCreateActionModal
						questionId={questionId}
						onClose={() => setShowCreateActionModal(false)}
					/>,
					document.getElementById('modal-root')
				)}
		</ActionsWrapper>
	);
}
