import React, { useState, Fragment } from 'react';
import { useQuery, useMutation } from '@apollo/client';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import Button from '@mui/material/Button';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';

import { useConfirmDialog } from 'components/contexts/ConfirmContext';
import { useSnackbar } from 'web/contexts/SnackbarContext';

import AssignmentRisksCreateDialog from 'web/components/panels/dialogs/AssignmentRisksCreateDialog';

import ErrorMessage from 'components/ErrorMessage';
import { StyledListWithA } from 'components/MaterialList';
import { RevisionText } from 'components/Revision';

import Panel from 'web/components/panels/Panel';

import { GET_ASSIGNMENT_RISKS } from 'api/queries/assignmentRiskQueries';
import { DELETE_ONE_ASSIGNMENT_RISK } from 'api/mutations/assignmentRiskMutations';

const AddButtonWrapper = styled.div`
	text-align: right;
`;

export const EmptyList = styled.div`
	background: white;
	padding: 16px;
	margin-bottom: 20px;
`;

export default function AssignmentRisksPanel({ companyId }) {
	const { notify } = useSnackbar();
	const { verify } = useConfirmDialog();

	const [showDialog, setShowDialog] = useState(false);

	const { data: { risks } = { risks: [] }, error: riskError } = useQuery(
		GET_ASSIGNMENT_RISKS,
		{
			variables: {
				companyId,
			},
		}
	);

	const [deleteRisk, { loading: deleteRiskLoading, error: deleteRiskError }] =
		useMutation(DELETE_ONE_ASSIGNMENT_RISK, {
			refetchQueries: ['getAssignmentRisks'],
		});

	return (
		<Fragment>
			<Panel
				title="Oppdragsrisikoanalyser"
				action={
					<AddButtonWrapper>
						<Button
							endIcon={<AddIcon size="small" />}
							size="medium"
							variant="contained"
							color="primary"
							disabled={showDialog}
							onClick={() => {
								setShowDialog(true);
							}}
						>
							Opprett ny
						</Button>
					</AddButtonWrapper>
				}
			>
				<ErrorMessage errors={[riskError, deleteRiskError]} />

				{risks?.length > 0 ? (
					<StyledListWithA>
						{risks.map(({ _id, name, revision, needsRevision }) => (
							<Fragment key={_id}>
								<ListItem
									secondaryAction={
										<IconButton
											edge="end"
											aria-label="Slett"
											role="button"
											disabled={deleteRiskLoading}
											onClick={async () => {
												try {
													if (
														!(await verify({
															title: 'Slett oppdragsrisikoanalyse',
															text: `Er du sikker på at du vil slette «${name}»?`,
														}))
													) {
														return;
													}
													const { data } =
														await deleteRisk({
															variables: {
																_id,
															},
														});

													if (data.deleted) {
														notify(
															`${name} ble slettet`
														);
													}
												} catch (err) {
													console.error(err);
												}
											}}
										>
											<DeleteIcon />
										</IconButton>
									}
								>
									<ListItemText>
										<Link
											to={`/bedrifter/${companyId}/oppdragsrisiko/${_id}/rediger/`}
										>
											{name}
										</Link>

										<RevisionText
											needsRevision={needsRevision}
											date={revision?.date}
											by={revision?.byDisplayName}
										/>
									</ListItemText>
								</ListItem>
								<Divider component="li" />
							</Fragment>
						))}
					</StyledListWithA>
				) : (
					<EmptyList>
						Bedriften har ingen oppdragsrisikoanalyser.
					</EmptyList>
				)}
			</Panel>

			<AssignmentRisksCreateDialog
				existingRisks={risks}
				companyId={companyId}
				open={showDialog}
				onClose={() => setShowDialog(false)}
			/>
		</Fragment>
	);
}
