import { gql } from '@apollo/client';

export const GET_ASSIGNMENT_RISKS = gql`
	query getAssignmentRisks($companyId: ID!) {
		risks: getAssignmentRisks(companyId: $companyId) {
			_id
			type
			name
			hasChanges
			revision {
				date
				byDisplayName
			}
			needsRevision
		}
	}
`;

export const FIND_ONE_ASSIGNMENT_RISK = gql`
	query findOneAssignmentRisk($_id: ID!) {
		risk: findOneAssignmentRisk(_id: $_id) {
			_id
			name
			description
			company {
				_id
				name
			}
			hasChanges
			revision {
				date
				byDisplayName
			}
			needsRevision
			categories {
				title
				questions {
					id
					title
					type
					helpText
					options
					dependsOn
					dependsOnValue
					value
				}
			}
		}
	}
`;
