import { BrowserRouter, Routes, Route } from 'react-router-dom';

import AccountantHome from 'web/screens/AccountantHome';

import Statistics from 'web/screens/Statistics';

import CompaniesArchive from 'web/screens/CompaniesArchive';
import CompaniesWithPostponedTaskDeadline from 'web/screens/companies/postponedDeadlines/CompaniesWithPostponedTaskDeadline';
import CompaniesCreate from 'web/screens/CompaniesCreate';
import CompaniesSingle from 'web/screens/CompaniesSingle';
import CompaniesEdit from 'web/screens/CompaniesEdit';

import CompaniesDocumentsSingle from 'web/screens/CompaniesDocumentsSingle';

import CompanyCategoriesArchive from 'web/screens/CompanyCategoriesArchive';
import CompanyCategoriesCreate from 'web/screens/CompanyCategoriesCreate';
import CompanyCategoriesEdit from 'web/screens/CompanyCategoriesEdit';

import CompanyContractEdit from 'web/screens/CompanyContractEdit';

import CompanyRoutinesEdit from 'web/screens/CompanyRoutinesEdit';

import AssignmentRiskEdit from 'web/screens/AssignmentRiskEdit';

import CompanyRiskEdit from 'web/screens/CompanyRiskEdit';

import CompanyControlEdit from 'web/screens/CompanyControlEdit';

import CompanyAssignmentTasksCreate from 'web/screens/CompanyAssignmentTasksCreate';
import CompanyAssignmentTasksEdit from 'web/screens/CompanyAssignmentTasksEdit';

import AuditingCompaniesArchive from 'web/screens/auditingCompanies/AuditingCompaniesArchive';
import AuditingCompaniesCreate from 'web/screens/auditingCompanies/AuditingCompaniesCreate';
import AuditingCompaniesEdit from 'web/screens/auditingCompanies/AuditingCompaniesEdit';

import AccountantsArchive from 'web/screens/AccountantsArchive';

import AuditorsArchive from 'web/screens/AuditorsArchive';

import UsersArchive from 'web/screens/UsersArchive';
import UsersEdit from 'web/screens/UsersEdit';
import UsersEditAccess from 'web/screens/UsersEditAccess';

import ExportScreen from 'web/screens/ExportScreen';

import SendSigningRequest from 'web/screens/SendSigningRequest';
import FileEdit from 'web/screens/FileEdit';
import ShareDocumentNode from 'web/screens/ShareDocumentNode';

import CompanyApplicationsArchive from 'web/screens/CompanyApplicationsArchive';
import CompanyApplicationSingle from 'web/screens/CompanyApplicationSingle';

import Help from 'web/screens/Help';
import HelpIndex from 'web/screens/HelpIndex';
import HelpSingle from 'web/screens/HelpSingle';

import HelpPagesArchive from 'web/screens/HelpPagesArchive';
import HelpPagesEdit from 'web/screens/HelpPagesEdit';

import NotFound from 'web/screens/NotFound';

import RouteRedirect from 'web/RouteRedirect';

export default function AdminRoutes() {
	return (
		<BrowserRouter>
			<Routes>
				<Route path="/" element={<AccountantHome />} />

				<Route path="/statistikk" element={<Statistics />} />

				<Route path="eksport" element={<ExportScreen />} />

				<Route path="bedrifter">
					<Route index element={<CompaniesArchive />} />

					<Route path="ny" element={<CompaniesCreate />} />

					<Route
						path="utsatte-oppgaver"
						element={<CompaniesWithPostponedTaskDeadline />}
					/>

					<Route path=":companyId">
						<Route index element={<CompaniesSingle />} />

						<Route path="rediger" element={<CompaniesEdit />} />

						<Route path=":documentNodeId">
							<Route
								index
								element={<CompaniesDocumentsSingle />}
							/>

							<Route path="rediger" element={<FileEdit />} />

							<Route
								path="signer"
								element={<SendSigningRequest />}
							/>

							<Route path="del" element={<ShareDocumentNode />} />
						</Route>

						<Route
							path="avtaler/:contractId/rediger"
							element={<CompanyContractEdit />}
						/>

						<Route
							path="rutiner/:routineId/rediger"
							element={<CompanyRoutinesEdit />}
						/>

						<Route
							path="oppdragsrisiko/:riskId/rediger"
							element={<AssignmentRiskEdit />}
						/>

						<Route
							path="hvitvasking/:riskId/rediger"
							element={<CompanyRiskEdit />}
						/>

						<Route
							path="kontroller/:controlId/rediger"
							element={<CompanyControlEdit />}
						/>

						<Route path="oppdrag">
							<Route
								path="ny"
								element={<CompanyAssignmentTasksCreate />}
							/>

							<Route
								path="planlegg"
								element={<CompanyAssignmentTasksEdit />}
							/>
						</Route>
					</Route>
				</Route>

				<Route path="bedriftskategorier">
					<Route index element={<CompanyCategoriesArchive />} />

					<Route path="ny" element={<CompanyCategoriesCreate />} />

					<Route
						path=":categoryId/rediger"
						element={<CompanyCategoriesEdit />}
					/>
				</Route>

				<Route path="revisorfirma">
					<Route index element={<AuditingCompaniesArchive />} />

					<Route path="ny" element={<AuditingCompaniesCreate />} />

					<Route
						path=":auditingCompanyId/rediger"
						element={<AuditingCompaniesEdit />}
					/>
				</Route>

				<Route
					path="regnskapsforere"
					element={<AccountantsArchive />}
				/>

				<Route path="revisorer" element={<AuditorsArchive />} />

				<Route path="brukere">
					<Route index element={<UsersArchive />} />

					<Route
						path="tilganger"
						element={<RouteRedirect redirect="/brukere" />}
					/>

					<Route path=":userId">
						<Route index element={<UsersEdit />} />

						<Route path="tilganger" element={<UsersEditAccess />} />
					</Route>
				</Route>

				<Route path="soknader">
					<Route index element={<CompanyApplicationsArchive />} />

					<Route
						path=":companyApplicationId"
						element={<CompanyApplicationSingle />}
					/>
				</Route>
				<Route path="hjelp" element={<Help />}>
					<Route index element={<HelpIndex />} />

					<Route path=":helpPageId" element={<HelpSingle />} />
				</Route>

				<Route path="hjelpesider">
					<Route index element={<HelpPagesArchive />} />

					<Route
						path=":helpPageId/rediger"
						element={<HelpPagesEdit />}
					/>
				</Route>

				<Route path="404" element={<NotFound />} />

				<Route path="*" element={<RouteRedirect redirect="/404" />} />
			</Routes>
		</BrowserRouter>
	);
}
