import styled from 'styled-components';

import Tooltip from '@mui/material/Tooltip';
import HelpIcon from '@mui/icons-material/Help';

const TooltipIconWrapper = styled.span`
	color: ${p => p.theme.palette.text.secondary};
	cursor: pointer;
	display: flex;
	justify-content: center;
	align-items: center;
	position: ${p => (p.$absolutePosition ? 'absolute' : 'relative')};
	right: ${p => (p.$absolutePosition ? '-30px' : 0)};
	height: 30px;
	width: 30px;
	text-align: center;
	line-height: 56px;
`;

const TooltipTextWrapper = styled.div`
	a {
		color: ${p => p.theme.palette.text.white};
	}
`;

export default function HelpText({ text, absolutePosition = false }) {
	if (!text) return null;

	return (
		<Tooltip
			title={
				<TooltipTextWrapper
					dangerouslySetInnerHTML={{
						__html: text,
					}}
				/>
			}
			placement="right"
		>
			<TooltipIconWrapper $absolutePosition={absolutePosition}>
				<HelpIcon />
			</TooltipIconWrapper>
		</Tooltip>
	);
}
