import { Fragment, useState } from 'react';
import { useQuery, useMutation } from '@apollo/client';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Divider from '@mui/material/Divider';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';

import { getFolderNameBySlug } from 'server/libs/defaultFolders';
import { pluralize } from 'utils/strings';
import { conjunctionFormat } from 'utils/arrays';

import CompanyRoutinesCreateDialog from 'web/screens/dialogs/companies/CompanyRoutinesCreateDialog';

import { RevisionText } from 'components/Revision';
import { InlineLoading } from 'components/Loading';
import Panel from 'web/components/panels/Panel';
import { EmptyList } from 'components/MaterialList';
import { StyledListWithA } from 'components/MaterialList';
import ErrorMessage from 'components/ErrorMessage';
import ErrorAlert from 'components/ErrorAlert';

import { GET_COMPANY_ROUTINES } from 'api/queries/companyRoutineQueries';
import { DELETE_COMPANY_ROUTINE } from 'api/mutations/companyRoutineMutations';
import { useSnackbar } from 'web/contexts/SnackbarContext';
import { useConfirmDialog } from 'components/contexts/ConfirmContext';

const Text = styled.div`
	color: ${p => p.theme.palette.status.subdued};
	font-size: 0.9rem;
	margin-top: 5px;
`;

const AddButtonWrapper = styled.div`
	text-align: right;
`;

export default function CompanyRoutinesPanel({ companyId }) {
	const [showDialog, setShowDialog] = useState(false);

	const {
		data: { routines } = { routines: [] },
		loading,
		error,
	} = useQuery(GET_COMPANY_ROUTINES, {
		variables: {
			companyId,
		},
	});

	return (
		<>
			<Panel
				title="Bedriftsrutiner"
				action={
					<AddButtonWrapper>
						<Button
							size="medium"
							variant="contained"
							color="primary"
							endIcon={<AddIcon size="small" />}
							onClick={() => setShowDialog(true)}
						>
							Opprett ny
						</Button>
					</AddButtonWrapper>
				}
			>
				<ErrorMessage errors={error} />

				{loading ? (
					<InlineLoading />
				) : (
					<CompanyRoutinesList routines={routines} />
				)}
			</Panel>

			<CompanyRoutinesCreateDialog
				companyId={companyId}
				activeRoutines={routines}
				open={showDialog}
				onClose={() => setShowDialog(false)}
			/>
		</>
	);
}

function CompanyRoutinesList({ routines }) {
	const { notify } = useSnackbar();
	const { verify } = useConfirmDialog();

	const [deleteRoutine, { error: deleteRoutineError }] = useMutation(
		DELETE_COMPANY_ROUTINE,
		{ refetchQueries: ['getCompanyRoutines'] }
	);

	if (!routines || routines.length === 0) {
		return <EmptyList>Bedriften har ingen rutiner</EmptyList>;
	}

	return (
		<>
			<ErrorAlert error={deleteRoutineError} />

			<StyledListWithA>
				{routines.map(
					({
						_id,
						name,
						companyId,
						folderPlacements,
						revision,
						needsRevision,
					}) => (
						<Fragment key={_id}>
							<ListItem
								secondaryAction={
									<IconButton
										edge="end"
										aria-label="Slett"
										role="button"
										onClick={async () => {
											try {
												if (
													!(await verify({
														title: `Slett bedriftsrutine`,
														text: `Er du sikker på at du vil slette ${name}?`,
													}))
												) {
													return;
												}

												const { data } =
													await deleteRoutine({
														variables: {
															_id,
														},
													});

												if (data.deleted) {
													notify(
														`${name} ble slettet`
													);
												}
											} catch (err) {
												console.error(err);
											}
										}}
									>
										<DeleteIcon />
									</IconButton>
								}
							>
								<ListItemText>
									<Link
										to={`/bedrifter/${companyId}/rutiner/${_id}/rediger/`}
									>
										{name}
									</Link>

									{folderPlacements ? (
										<Text>
											<strong>
												{pluralize(
													'Mappeplassering: ',
													'Mappeplasseringer: ',
													Text
												)}
											</strong>

											<span>
												{conjunctionFormat(
													folderPlacements.map(slug =>
														getFolderNameBySlug(
															slug
														)
													)
												)}
											</span>
										</Text>
									) : (
										<Text>Vises ikke i noen mappe</Text>
									)}

									<RevisionText
										needsRevision={needsRevision}
										date={revision?.date}
										by={revision?.byDisplayName}
									/>
								</ListItemText>
							</ListItem>
							<Divider component="li" />
						</Fragment>
					)
				)}
			</StyledListWithA>
		</>
	);
}
