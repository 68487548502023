import { useQuery, useMutation } from '@apollo/client';
import styled from 'styled-components';
import Button from '@mui/material/Button';
import ClearIcon from '@mui/icons-material/Clear';

import dayjs from 'utils/dates';

import { useWebApp } from 'web/contexts/WebContexts';
import { useSnackbar } from 'web/contexts/SnackbarContext';

import { InlineLoading } from 'components/Loading';
import ErrorAlert from 'components/ErrorAlert';

import SigningRequestsWidget from 'web/components/widgets/SigningRequestsWidget';

import DesktopLayout, { Content } from 'web/components/Layout';
import SelectTenant from 'web/components/SelectTenant';
import CompanyInfo from 'web/components/CompanyInfo';
import DocumentsWidget from 'web/components/widgets/DocumentsWidget';
import CompanyRoutinesListWidget from 'web/components/widgets/CompanyRoutinesListWidget';

import { HOME_DASHBOARD_QUERY } from 'api/queries/homeQueries';
import { CLEAR_RECENT_DOCUMENTS } from 'api/mutations/documentMutations';

const oneMonthAgo = dayjs().subtract(1, 'month').toDate();

const Grid = styled.div`
	display: grid;
	grid-template-columns: repeat(12, 1fr);
	gap: 30px;
	margin-bottom: 30px;
`;

const Column = styled.div`
	grid-column: span ${({ span }) => span};
`;

export default function Home() {
	const { data } = useWebApp();

	if (!data?.tenant?._id) {
		return <SelectTenant />;
	}

	return <HomeWithCompany companyId={data.tenant._id} />;
}

function HomeWithCompany({ companyId }) {
	return (
		<DesktopLayout
			title="Hjem"
			breadcrumbs={[
				{
					to: '/',
					label: 'Hjem',
				},
			]}
		>
			<Content>
				<Dashboard companyId={companyId} />
			</Content>
		</DesktopLayout>
	);
}

function Dashboard({ companyId }) {
	const { notify } = useSnackbar();

	const {
		data: {
			company,
			recentDocuments,
			unSignedDocuments,
			mySignatureRequests,
		} = {
			company: {},
			recentDocuments: [],
			unSignedDocuments: [],
			mySignatureRequests: [],
		},

		loading: dashboardLoading,
		error: dashboardError,
	} = useQuery(HOME_DASHBOARD_QUERY, {
		variables: {
			companyId: companyId,
			createdAt: {
				gt: oneMonthAgo,
			},
			type: 'file',
		},
	});

	const [
		clearRecentDocuments,
		{
			loading: clearRecentDocumentsLoading,
			error: clearResentDocumentsError,
		},
	] = useMutation(CLEAR_RECENT_DOCUMENTS, {
		refetchQueries: ['homeDashboardData'],
	});

	if (dashboardLoading) {
		return <InlineLoading />;
	}

	if (dashboardError) {
		return <ErrorAlert error={dashboardError} />;
	}

	return (
		<div>
			<ErrorAlert error={clearResentDocumentsError} />

			<CompanyInfo company={company} showFavoriteToggle={false} />

			<Grid>
				<Column span={4}>
					<CompanyRoutinesListWidget companyId={companyId} />
				</Column>

				{mySignatureRequests && (
					<Column span={4}>
						{' '}
						<SigningRequestsWidget
							title="Dokumenter som avventer min signering"
							titleIcon="signature"
							signingRequests={mySignatureRequests}
						/>
					</Column>
				)}

				<Column span={4}>
					<DocumentsWidget
						title="Dokumenter som venter på signatur"
						titleIcon="file-signature"
						documents={unSignedDocuments.items}
					/>
				</Column>

				<Column span={4}>
					<DocumentsWidget
						title="Dokumenter som er nylig lastet opp"
						titleIcon="file"
						action={
							<>
								<Button
									endIcon={<ClearIcon />}
									disabled={clearRecentDocumentsLoading}
									onClick={async () => {
										try {
											const ids =
												recentDocuments.items.map(
													d => d._id
												);

											const { data } =
												await clearRecentDocuments({
													variables: { ids },
												});

											console.log(data);

											if (data?.ok) {
												notify(
													'Nylige dokumenter ble markert som sett'
												);
											}
										} catch (err) {
											console.error(err);
										}
									}}
								>
									Skjul
								</Button>
							</>
						}
						documents={recentDocuments.items}
					/>
				</Column>
			</Grid>
		</div>
	);
}
