import { useState, Fragment } from 'react';
import { useQuery, useMutation } from '@apollo/client';
import { useParams, useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { useFormContext, useFieldArray } from 'react-hook-form';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import SearchIcon from '@mui/icons-material/Search';
import AddIcon from '@mui/icons-material/Add';
import NotificationsIcon from '@mui/icons-material/Notifications';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import MUITextField from '@mui/material/TextField';
import Tooltip from '@mui/material/Tooltip';
import * as yup from 'yup';

import { formatDate } from 'utils/dates';

import { useConfirmDialog } from 'components/contexts/ConfirmContext';
import { useSnackbar } from 'web/contexts/SnackbarContext';

import ErrorMessage from 'components/ErrorMessage';
import Revision from 'components/Revision';

import DesktopLayout, {
	WideContent,
	StickyAction,
} from 'web/components/Layout';

import DeleteButton from 'components/buttons/DeleteButton';

import Form, {
	TextField,
	DatePicker,
	Checkbox,
	ButtonGroup,
	Select,
	MultiSelect,
} from 'web/form/Form';

import { FIND_ONE_COMPANY_CONTRACT } from 'api/queries/companyContractQueries';

import {
	UPDATE_ONE_COMPANY_CONTRACT,
	DUPLICATE_ONE_COMPANY_CONTRACT,
	DELETE_ONE_COMPANY_CONTRACT,
	SEND_COMPANY_CONTRACT_TO_SIGNING,
	SEND_COMPANY_CONTRACT_SIGNING_REQUEST_NOTIFICATION,
} from 'api/mutations/companyContractMutations';

const Title = styled.h1`
	margin: 0 0 30px 0;
`;

const Panel = styled.div`
	background-color: ${p => p.theme.palette.background.default};
	border: 1px solid ${p => p.theme.palette.background.paper};
	padding: 20px;
	margin-bottom: 20px;
`;

export default function CompanyContractEdit() {
	const { notify } = useSnackbar();
	const { companyId, contractId } = useParams();

	const {
		data: { contract } = { contract: null },
		loading: contractLoading,
		error: contractError,
	} = useQuery(FIND_ONE_COMPANY_CONTRACT, {
		variables: {
			_id: contractId,
		},
	});

	const [
		updateContract,
		{ loading: updateContractLoading, error: updateContractError },
	] = useMutation(UPDATE_ONE_COMPANY_CONTRACT, {
		refetchQueries: ['findOneCompanyContract'],
	});

	const [
		deleteContract,
		{ loading: deleteContractLoading, error: deleteContractError },
	] = useMutation(DELETE_ONE_COMPANY_CONTRACT);

	yup.setLocale({
		mixed: {
			required: 'Feltet er påkrevd',
		},
		string: {
			min: ({ min }) => `Feltet må være på minst ${min} tegn`,
		},
		date: {
			min: ({ min }) =>
				`Datoen kan ikke være før ${formatDate(min, 'DD/MM/YYYY')}`,
		},
	});

	const schema = yup
		.object({
			startDate: yup.date().required(),
		})
		.required();

	async function handleFormSubmit(data) {
		try {
			const result = await updateContract({
				variables: data,
			});

			if (result?.data) {
				notify(
					<div>
						Oppdragsavtalen ble lagret!{' '}
						<a
							href={`${process.env.REACT_APP_CONTRACT_SIGN_URL}/avtaler/${contract._id}`}
							target="_blank"
							rel="noreferrer"
							style={{ color: 'white' }}
						>
							Se avtale
						</a>
					</div>
				);
			}
		} catch (err) {
			console.error(err);
		}
	}

	return (
		<DesktopLayout
			title="Rediger rutine"
			loading={!contract}
			breadcrumbs={[
				{
					to: '/bedrifter/',
					label: 'Bedrifter',
				},
				{
					to: `/bedrifter/${contract?.company?._id}/rediger/`,
					label: contract?.company?.name || '...',
				},
				{
					to: `/bedrifter/${contract?.company?._id}/rediger/?tab=oppdrag`,
					label: 'Oppdragsavtaler',
				},
				{
					label: 'Rediger',
				},
			]}
		>
			{contract && (
				<WideContent>
					<Title>
						{contract.typeLabel} for {contract.company.name}
					</Title>

					<ErrorMessage
						errors={[
							contractError,
							updateContractError,
							deleteContractError,
						]}
					/>

					<Revision
						date={contract?.updatedAt || contract?.createdAt}
						by={
							contract?.updatedByDisplayName ||
							contract?.createdByDisplayName
						}
					/>

					<Form
						onSubmit={handleFormSubmit}
						schema={schema}
						values={contract}
					>
						<div>
							<ContractActions
								contract={contract}
								onSubmit={handleFormSubmit}
							/>

							<SectionPanel title="Oppstart">
								<DatePicker
									name="startDate"
									label="Oppstartsdato"
									required
								/>
							</SectionPanel>

							<SectionPanel title="Innledning">
								<TextField
									name="introText"
									label="Innledning"
									fullWidth
									multiline
									rows={4}
								/>
							</SectionPanel>

							<SectionPanel title="Kontakt">
								<Select
									name="clientContactId"
									label="Kontakperson kunde"
									options={contract.company?.boardMembers?.map(
										({ _id, name }) => ({
											value: _id,
											label: name,
										})
									)}
								/>
							</SectionPanel>

							<SectionPanel title="Signeringer">
								<MultiSelect
									name="signingIds"
									label="Velg signeringer"
									options={contract.company?.users?.map(
										({ _id, name }) => ({
											value: _id,
											label: name,
										})
									)}
								/>
							</SectionPanel>

							<ServicesFields name="services" />

							<AssignmentsFields name="assignments" />

							<AttestationRoutinesFields name="attestationRoutines" />

							<SoftwareFields name="software" />

							<FeesFields
								name="hourlyRates"
								title="Timepriser"
								info="*) Pris per time"
							/>

							<FeesFields
								name="fixedRates"
								title="Volumpriser"
								info="*) Pris per stykk"
							/>
						</div>

						<StickyAction>
							<DeleteButton
								disabled={deleteContractLoading}
								onClick={async () => {
									const { data } = await deleteContract({
										variables: {
											_id: contractId,
										},
									});

									if (!data.deleted) {
										throw new Error(
											'Det oppstod en feil ved sletting!'
										);
									}

									notify('Oppdragsavtelen ble slettet!');
								}}
								redirect={`/bedrifter/${companyId}/rediger/?tab=oppdrag`}
							>
								Slett avtale
							</DeleteButton>

							<Button
								type="submit"
								variant="contained"
								size="large"
								disabled={
									contract?.isSentToSigning ||
									contractLoading ||
									updateContractLoading
								}
							>
								{updateContractLoading ? 'Lagrer…' : 'Lagre'}
							</Button>
						</StickyAction>
					</Form>
				</WideContent>
			)}
		</DesktopLayout>
	);
}

const SectionPanelWrapper = styled.div`
	margin-bottom: 20px;
`;

const SectionPanelTitle = styled.h3`
	margin: 0 0 20px 0;
`;

const SectionPanelIntro = styled.p`
	font-weight: bold;
	margin-bottom: 20px;
`;

const SectionPanelContent = styled.div``;

function SectionPanel({ title = null, intro = null, children }) {
	return (
		<SectionPanelWrapper>
			{title && <SectionPanelTitle>{title}</SectionPanelTitle>}

			<Panel>
				{intro && <SectionPanelIntro>{intro}</SectionPanelIntro>}

				<SectionPanelContent>{children}</SectionPanelContent>
			</Panel>
		</SectionPanelWrapper>
	);
}

const ContractActionContent = styled.div`
	display: grid;
	grid-template-columns: 2fr 1.5fr;
	gap: 30px;
	align-items: start;
`;

const ContractActionButtonWrapper = styled.div`
	display: flex;
	justify-content: flex-end;
	align-items: center;
	gap: 10px;
	text-align: right;
`;

function ContractActions({ contract, onSubmit }) {
	const { verify } = useConfirmDialog();
	const { notify } = useSnackbar();
	const navigate = useNavigate();
	const { handleSubmit } = useFormContext();

	const [
		duplicateContract,
		{ loading: duplicateContractLoading, error: duplicateContractError },
	] = useMutation(DUPLICATE_ONE_COMPANY_CONTRACT);

	const [
		sendToSigning,
		{ loading: sendToSigningLoading, error: sendToSigningError },
	] = useMutation(SEND_COMPANY_CONTRACT_TO_SIGNING, {
		refetchQueries: ['findOneCompanyContract'],
	});

	return (
		<SectionPanel>
			<ErrorMessage
				errors={[duplicateContractError, sendToSigningError]}
			/>

			<ContractActionContent>
				<div>
					{contract.isSentToSigning && (
						<div>
							<p>
								Kontrakten er sendt til signering og kan ikke
								lenger redigeres.
								<br /> Ønsker du å gjøre endringer kan
								kontrakten dupliseres.
							</p>

							<SigneesStatus
								contractId={contract._id}
								signingUsers={contract.signingUsers}
							/>
						</div>
					)}
				</div>

				<ContractActionButtonWrapper>
					{contract.isSentToSigning ? (
						<>
							<Button
								startIcon={<ContentCopyIcon />}
								disabled={duplicateContractLoading}
								onClick={async () => {
									try {
										if (
											!(await verify({
												title: 'Dupliser avtale',
												text: 'Er du sikker på at du ønsker å duplisere denne avtalen? Eksisterende avtale og signeringer vil bli kansellert.',
											}))
										) {
											return;
										}

										const { data } =
											await duplicateContract({
												variables: {
													_id: contract._id,
												},
											});

										if (data.contract) {
											notify(
												'Oppdragsavtelen ble duplisert!'
											);

											navigate(
												`/bedrifter/${contract.company._id}/avtaler/${data.contract._id}/rediger`
											);
										}
									} catch (err) {
										console.error(err);
									}
								}}
							>
								Dupliser
							</Button>

							<Button
								variant="outlined"
								onClick={async () => {
									window.open(
										`${process.env.REACT_APP_CONTRACT_SIGN_URL}/avtaler/${contract._id}`,
										'_blank',
										'noopener,noreferrer'
									);
								}}
							>
								Se oppdragsavtale
							</Button>
						</>
					) : (
						<>
							<Button
								startIcon={<SearchIcon />}
								onClick={async () => {
									await handleSubmit(onSubmit)();

									window.open(
										`${process.env.REACT_APP_CONTRACT_SIGN_URL}/avtaler/${contract._id}`,
										'_blank',
										'noopener,noreferrer'
									);
								}}
							>
								Forhåndsvis
							</Button>

							<Button
								variant="outlined"
								disabled={sendToSigningLoading}
								onClick={async () => {
									try {
										if (
											!(await verify({
												title: 'Send til signering',
												text: `Er du sikker på at du ønsker å sende kontrakten til signering? Det vil ikke være mulig å gjøre endringer etter dette.`,
											}))
										) {
											return;
										}

										await handleSubmit(onSubmit)();

										const { data } = await sendToSigning({
											variables: {
												_id: contract._id,
											},
										});

										if (data?.ok) {
											notify(
												'Oppdragsavtalen ble sendt til signering!'
											);
										}
									} catch (err) {
										console.error(err);
									}
								}}
							>
								Send til signering
							</Button>
						</>
					)}
				</ContractActionButtonWrapper>
			</ContractActionContent>
		</SectionPanel>
	);
}

const SigneesStatusTitle = styled.h4``;

function SigneesStatus({ contractId, signingUsers }) {
	if (!signingUsers || signingUsers.length === 0) {
		return <div>Oppdragsavtalen mangler signeringer</div>;
	}

	return (
		<div>
			<SigneesStatusTitle>Signaturstatus</SigneesStatusTitle>

			<div>
				{signingUsers.map(signee => (
					<SigneesStatusSignee
						key={signee._id}
						contractId={contractId}
						signee={signee}
					/>
				))}
			</div>
		</div>
	);
}

const SigneesStatusSigneeWrapper = styled.div`
	margin-bottom: 10px;
`;

const SigneesStatusSigneeContent = styled.div`
	display: flex;
	align-items: center;
	gap: 10px;
`;

const SigneesStatusSigneeName = styled.div`
	flex: 1;
`;

const SigneesStatusSigneeActions = styled.div`
	display: flex;
	align-items: center;
	gap: 10px;
`;

const SigneesStatusSigneeStatus = styled.div`
	background-color: ${({ $signed, theme }) =>
		$signed
			? theme.palette.status.light.green
			: theme.palette.status.light.yellow};
	border-radius: 50px;
	font-size: 14px;
	min-width: 70px;
	padding: 5px 10px;
	text-align: center;
`;

function SigneesStatusSignee({ contractId, signee }) {
	const { verify } = useConfirmDialog();
	const { notify } = useSnackbar();

	const [signatureRequestSent, setSignatureRequestSent] = useState(false);

	const [resendSigningRequest, { loading, error }] = useMutation(
		SEND_COMPANY_CONTRACT_SIGNING_REQUEST_NOTIFICATION
	);

	return (
		<SigneesStatusSigneeWrapper>
			<ErrorMessage errors={[error]} />

			<SigneesStatusSigneeContent>
				<SigneesStatusSigneeName>{signee.name}</SigneesStatusSigneeName>

				<SigneesStatusSigneeActions>
					<Tooltip title="Send påminnelse">
						<IconButton
							disabled={
								loading || signee.signed || signatureRequestSent
							}
							onClick={async () => {
								try {
									if (
										!(await verify({
											title: 'Nytt varsel',
											text: `Er du sikker på at du ønsker å sende ut et nytt varsel til ${signee.name}?`,
										}))
									) {
										return;
									}

									const { data } = await resendSigningRequest(
										{
											variables: {
												_id: contractId,
												signingUserId: signee._id,
											},
										}
									);

									if (data) {
										setSignatureRequestSent(true);

										notify('Nytt varsel ble sendt!');
									}
								} catch (err) {
									console.error(err);
								}
							}}
							size="small"
							aria-label="Send påminnelse"
						>
							<NotificationsIcon />
						</IconButton>
					</Tooltip>
				</SigneesStatusSigneeActions>

				<SigneesStatusSigneeStatus $signed={signee.signed}>
					{signee.signed ? 'Signert' : 'Venter'}
				</SigneesStatusSigneeStatus>
			</SigneesStatusSigneeContent>
		</SigneesStatusSigneeWrapper>
	);
}

const Fields = styled.div`
	margin-bottom: 10px;
`;

function ServicesFields({ name }) {
	const { control, watch } = useFormContext();

	const { fields, append, remove } = useFieldArray({
		control,
		name,
	});

	return (
		<SectionPanel title="Oppdragspesifisering">
			<div>
				<Fields>
					{fields.map((field, index) => {
						const billings = watch(`${name}.${index}.billings`);

						return (
							<Field key={field.id}>
								<FieldGrow>{field.name}</FieldGrow>

								{billings?.includes('Fastpris') && (
									<TextField
										name={`${name}.${index}.fixedPrice`}
										label="Fastpris"
										size="small"
										prefix="kr"
										textAlign="right"
										sx={{
											maxWidth: '120px',
										}}
									/>
								)}

								<ButtonGroup
									name={`${name}.${index}.billings`}
									label="Billings"
									options={[
										{
											value: 'Fastpris',
											label: 'Fastpris',
										},
										{
											value: 'Medgått tid',
											label: 'Medgått tid',
										},
										{
											value: 'Volumbasert',
											label: 'Volumbasert',
										},
									]}
								/>

								<RemoveFieldButton
									onClick={() => remove(index)}
								/>
							</Field>
						);
					})}
				</Fields>

				<AddFieldButton
					label="Annet oppdrag; beskriv her"
					onClick={value =>
						append({ name: value, billings: [], fixedPrice: '0' })
					}
				/>
			</div>
		</SectionPanel>
	);
}

const CommentField = styled.div`
	margin: 30px;
`;

function AssignmentsFields({ name }) {
	const { control } = useFormContext();

	const { fields, append } = useFieldArray({
		control,
		name,
	});

	return (
		<SectionPanel title="Oppdragsbeskrivelse">
			<div>
				<Fields>
					{fields.map((field, assignmentIndex) => {
						return (
							<div key={field.id}>
								<Field>
									<FieldGrow>
										<strong>{field.name}</strong>
									</FieldGrow>
								</Field>

								<CommentField>
									<TextField
										name={`${name}.${assignmentIndex}.comment`}
										label="Kommentar"
										maxRows={20}
										multiline
										fullWidth
									/>
								</CommentField>

								<AssignmentTasksFields
									name={`${name}.${assignmentIndex}.tasks`}
									control={control}
								/>
							</div>
						);
					})}
				</Fields>

				<h3>Legg til ny kategori</h3>

				<AddFieldButton
					label="Skriv inn navn på kategori"
					onClick={value =>
						append({
							name: value,
							tasks: [],
						})
					}
				/>
			</div>
		</SectionPanel>
	);
}

const AssignmentTasksGrid = styled.div`
	display: grid;
	grid-template-columns: 2.5fr 0.8fr 1.2fr 1.2fr 30px;
	align-items: center;
	gap: 10px;
	margin-bottom: 10px;
`;

const AddAssignmentTaskButtonWrapper = styled.div`
	margin: 20px 0 30px 0;
`;

function AssignmentTasksFields({ name, control }) {
	const { fields, remove, append } = useFieldArray({
		control,
		name,
	});

	return (
		<div>
			<AssignmentTasksGrid>
				{fields.map((field, index) => (
					<Fragment key={index}>
						<Checkbox
							name={`${name}.${index}.active`}
							label={field.name}
						/>

						<Select
							name={`${name}.${index}.frequency`}
							label="Frekvens"
							options={[
								{
									value: 'På forespørsel',
									label: 'På forespørsel',
								},
								{
									value: 'Løpende',
									label: 'Løpende',
								},
								{
									value: 'Daglig',
									label: 'Daglig',
								},
								{
									value: 'Ukentlig',
									label: 'Ukentlig',
								},
								{
									value: 'Månedlig',
									label: 'Månedlig',
								},
								{
									value: '2-månedlig',
									label: '2-månedlig',
								},
								{
									value: 'Kvartalsvis',
									label: 'Kvartalsvis',
								},
								{
									value: 'Tertialvis',
									label: 'Tertialvis',
								},
								{
									value: 'Halvårlig',
									label: 'Halvårlig',
								},
								{
									value: 'Årlig',
									label: 'Årlig',
								},
							]}
							size="small"
							fullWidth
						/>

						<TextField
							name={`${name}.${index}.clientDeadline`}
							label="Frist kunde"
							size="small"
						/>

						<TextField
							name={`${name}.${index}.contractorDeadline`}
							label="Frist regnskapsfører"
							size="small"
						/>

						<RemoveFieldButton onClick={() => remove(index)} />
					</Fragment>
				))}
			</AssignmentTasksGrid>

			<AddAssignmentTaskButtonWrapper>
				<AddFieldButton
					label="Andre krav; beskriv her"
					onClick={value =>
						append({
							name: value,
							frequency: null,
							clientDeadline: '',
							contractorDeadline: '',
							active: true,
						})
					}
				/>
			</AddAssignmentTaskButtonWrapper>
		</div>
	);
}

const Field = styled.div`
	display: flex;
	align-items: center;
	gap: 20px;
	margin-bottom: 8px;
`;

const FieldGrow = styled.div`
	flex: 1;
`;

function AttestationRoutinesFields({ name }) {
	const { control } = useFormContext();

	const { fields, append, remove } = useFieldArray({
		control,
		name,
	});

	return (
		<SectionPanel title="Attestasjon og godkjenning">
			<div>
				<Fields>
					{fields.map((field, index) => (
						<Field key={field.id}>
							<FieldGrow>
								<Checkbox
									name={`${name}.${index}.active`}
									label={field.name}
								/>
							</FieldGrow>

							<RemoveFieldButton onClick={() => remove(index)} />
						</Field>
					))}
				</Fields>

				<AddFieldButton
					label="Annen rutine; beskriv her"
					onClick={value => append({ name: value, active: true })}
				/>
			</div>
		</SectionPanel>
	);
}

const SoftwareInfoText = styled.p`
	font-weight: bold;
	text-align: right;
	margin: 0 140px 10px 0;
`;

const SoftwareModules = styled.div`
	padding: 0 10px 20px 10px;
`;

const SofwareModulesTitle = styled.h4`
	margin: 0 0 10px 0;
`;

function SoftwareFields({ name }) {
	const { control, watch } = useFormContext();

	const { fields, append, remove } = useFieldArray({
		control,
		name,
	});

	return (
		<SectionPanel title="Programvare">
			{fields?.length > 0 && (
				<SoftwareInfoText>Lisenshaver</SoftwareInfoText>
			)}
			<Fields>
				{fields.map((field, fieldIndex) => {
					const license = watch(`${name}.${fieldIndex}.license`);

					return (
						<div key={field.id}>
							<Field>
								<FieldGrow>{field.name}</FieldGrow>

								<ButtonGroup
									name={`${name}.${fieldIndex}.license`}
									label="Lisenshaver"
									options={[
										{
											value: 'Kunde',
											label: 'Kunde',
										},
										{
											value: 'Regnskapsforetaket',
											label: 'Regnskapsforetaket',
										},
									]}
									exclusive
								/>

								<RemoveFieldButton
									onClick={() => remove(fieldIndex)}
								/>
							</Field>

							{license && (
								<div>
									{license === 'Regnskapsforetaket' &&
										field.modules && (
											<SoftwareModules>
												<SofwareModulesTitle>
													Moduler
												</SofwareModulesTitle>

												{field.modules.map(
													(module, moduleIndex) => (
														<Field
															key={moduleIndex}
														>
															<FieldGrow>
																<Checkbox
																	name={`${name}.${fieldIndex}.modules.${moduleIndex}.active`}
																	label={
																		module.name
																	}
																	size="small"
																/>
															</FieldGrow>

															{/* <div>{module.unit}</div> */}

															<TextField
																name={`${name}.${fieldIndex}.modules.${moduleIndex}.price`}
																size="small"
																prefix="kr"
																textAlign="right"
																aria-label="Pris"
																sx={{
																	maxWidth:
																		'120px',
																}}
															/>
														</Field>
													)
												)}
											</SoftwareModules>
										)}

									{license === 'Kunde' && (
										<SoftwareModules>
											<SofwareModulesTitle>
												Tilganger
											</SofwareModulesTitle>

											<Checkbox
												name={`${name}.${fieldIndex}.adminRights`}
												label="Regnskapsforetaket skal ha
												administratorrettigheter."
											/>
										</SoftwareModules>
									)}
								</div>
							)}
						</div>
					);
				})}
			</Fields>
			<AddFieldButton
				label="Annen programvare; beskriv her"
				onClick={value =>
					append({
						name: value,
						license: 'Regnskapsforetaket',
						modules: null,
					})
				}
			/>
		</SectionPanel>
	);
}

const AddFieldButtonWrapper = styled.div`
	display: flex;
	gap: 20px;
	align-items: center;
`;

const AddFieldButtonError = styled.div`
	color: ${p => p.theme.palette.status.error};
`;

function AddFieldButton({ label, onClick }) {
	const [value, setValue] = useState('');
	const [error, setError] = useState('');

	function handleOnClick() {
		if (!value.trim()) {
			setError('Feltet er påkrevd!');
			return;
		}

		onClick(value);

		setValue('');
		setError('');
	}

	return (
		<AddFieldButtonWrapper>
			<MUITextField
				label={label}
				value={value}
				onChange={event => setValue(event.target.value)}
				size="small"
				sx={{ minWidth: '400px' }}
				error={!!error}
				onKeyPress={e => {
					if (e.key === 'Enter') {
						e.preventDefault();

						handleOnClick();
					}
				}}
			/>

			<Button
				type="button"
				size="medium"
				variant="contained"
				endIcon={<AddIcon size="small" />}
				onClick={handleOnClick}
			>
				Legg til
			</Button>

			{error && <AddFieldButtonError>{error}</AddFieldButtonError>}
		</AddFieldButtonWrapper>
	);
}

function RemoveFieldButton({ onClick }) {
	const { verify } = useConfirmDialog();

	return (
		<IconButton
			edge="end"
			aria-label="Slett oppføring"
			onClick={async () => {
				if (
					!(await verify({
						title: 'Slett oppføring',
						text: 'Er du sikker på at du ønsker å slette oppføringen?',
					}))
				) {
					return;
				}

				onClick();
			}}
		>
			<DeleteIcon />
		</IconButton>
	);
}

const InfoText = styled.p`
	font-style: italic;
	text-align: right;
	margin-top: 20px;
`;

function FeesFields({ name, title, info = null }) {
	const { control } = useFormContext();

	const { fields, append, remove } = useFieldArray({
		control,
		name,
	});

	return (
		<SectionPanel title={title}>
			<Fields>
				{fields.map((field, index) => (
					<Field key={field.name}>
						<FieldGrow>
							<Checkbox
								name={`${name}.${index}.active`}
								label={field.name}
							/>
						</FieldGrow>

						<TextField
							name={`${name}.${index}.value`}
							size="small"
							prefix="kr"
							textAlign="right"
							aria-label="Pris"
							sx={{
								maxWidth: '120px',
							}}
						/>

						<RemoveFieldButton onClick={() => remove(index)} />
					</Field>
				))}
			</Fields>

			{info && <InfoText>{info}</InfoText>}

			<AddFieldButton
				label="Annen pris; beskriv her"
				onClick={value =>
					append({
						name: value,
						value: '0,00',
						active: true,
					})
				}
			/>
		</SectionPanel>
	);
}
