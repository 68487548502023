import { Grid, GridItem } from 'web/components/company/GridLayout';

import CompanyRisksPanel from 'web/components/panels/CompanyRisksPanel';

export default function RiskFormsTab({ company }) {
	if (!company) return null;

	return (
		<Grid>
			<GridItem>
				<CompanyRisksPanel companyId={company._id} />
			</GridItem>
		</Grid>
	);
}
